/*global EaseScroll, lazyload*/

'use strict';

import { on, off, throttle, isInViewport } from './modules/_fn';

/* ---------------------------------------- [START] IE Global Setting */
// 舊IE提醒
const userAgent = window.navigator.userAgent;
if (
	userAgent.indexOf('MSIE 7.0') > 0 ||
	userAgent.indexOf('MSIE 8.0') > 0 ||
	userAgent.indexOf('MSIE 9.0') > 0 ||
	userAgent.indexOf('MSIE 10.0') > 0 ||
	!!userAgent.match(/Trident.*rv\:11\./) // IE11
) {
	location.href = 'browser.html';
}

/* 防止IE沒有 JS element.remove() */
/* Create Element.remove() function if not exist */
if (!('remove' in Element.prototype)) {
	Element.prototype.remove = function () {
		if (this.parentNode) {
			this.parentNode.removeChild(this);
		}
	};
}
/* ---------------------------------------- [END] IE Global Setting */
(function (window, document) {
	on(window, 'load', function () {
		// Start
		$(document).foundation();
		// $('body').addClass('is-loaded');
	});

	/* ---------------------------------------- [START] Windows Setting */
	const html = document.documentElement;
	let ww = window.innerWidth;
	let wh = window.innerHeight;
	let ws = 0;
	function getScrollTop(target = window) {
		return (target.pageYOffset || html.scrollTop) - (html.clientTop || 0);
	}
	function getWinSet() {
		ww = window.innerWidth;
		wh = window.innerHeight;
		ws = getScrollTop();
	}
	on(window, 'load', getWinSet);
	on(window, 'resize', throttle(getWinSet, 50, 100));
	/* ---------------------------------------- [END] Windows Setting */

	/* ---------------------------------------- [START] 取得裝置判斷 */
	// 取得裝置判斷
	let isMobile = false;
	let isTablet = false;
	let isPhone = false;

	const deviceDetect = function () {
		// IsPhone
		isPhone = ww <= 640;

		// IsMobile
		// 防止測試時一直用開發者工具Resize出現Bug
		isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
		if (isMobile) html.classList.add('is-mobile');
		else html.classList.remove('is-mobile');

		// IsTablet
		if (navigator.userAgent.match(/Android/i)) {
			if (!navigator.userAgent.match(/Mobile/i)) {
				isTablet = true;
			}
		} else if (navigator.userAgent.match(/BlackBerry|iPad|Opera Mini|IEMobile/i)) {
			isTablet = true;
		}
	};

	deviceDetect();
	on(window, 'resize', throttle(deviceDetect, 50, 100));
	/* ---------------------------------------- [END] 取得裝置判斷 */

	/* ---------------------------------------- [START] 判斷browser */
	var ua = navigator.userAgent;
	var browser = {
		isChrome: /chrome/i.test(ua),
		isFirefox: /firefox/i.test(ua),
		isSafari: /safari/i.test(ua),
		isIE: /msie/i.test(ua) || /trident/i.test(ua),
		isEdge: /edge/i.test(ua),
	};

	// 修正數值browser
	if (browser.isChrome) browser.isSafari = false;
	if (browser.isEdge) {
		browser.isChrome = false;
		browser.isSafari = false;
	}

	var browserIs;
	for (var key in browser) {
		if (browser[key]) {
			browserIs = key.split('is')[1];
			// 增加Class
			document.documentElement.classList.add(browserIs.toLowerCase());
			document.documentElement.browser = browserIs;
			break;
		}
	}
	browser.is = browserIs;

	// ----------------------------
	// 判斷 裝置
	// iOS
	var isiOS = ua.toLowerCase().match(/(iphone|ipod|ipad)/);
	isiOS && html.classList.add('ios');
	/* ---------------------------------------- [END] 判斷browser */

	/* ----------------------------------- [START] Loader 移除 */
	// var loaderRemove = function () {
	// 	var loader = document.querySelector('#loader');

	// 	loader.classList.add('loaded');
	// 	setTimeout(function () {
	// 		loader.remove();
	// 	}, 2000);
	// };
	// window.addEventListener('load', loaderRemove);
	/* ----------------------------------- [END] Loader 移除 */

	/* ---------------------------------------- [START] Ease scroll */
	var buildEaseScroll = function () {
		if (window.EaseScroll === undefined) return false;
		const es = new EaseScroll({
			frameRate: 60,
			animationTime: 1000,
			stepSize: 100,
			pulseAlgorithm: 1,
			pulseScale: 6,
			pulseNormalize: 1,
			accelerationDelta: 20,
			accelerationMax: 1,
			keyboardSupport: true,
			arrowScroll: 30,
			touchpadSupport: true,
			fixedBackground: true,
			// disabledClass: 'modal-open',

			/* Browser Setting Control */
			browser: {
				Chrome: true,
				FireFox: false,
				Safari: true,
				IE: true,
				Edge: true,
			},
		});
	};
	// on(window, 'load', buildEaseScroll);
	/* ---------------------------------------- [END] Ease scroll */

	/* ---------------------------------------- [START] ScrollTriggerAni */
	// 有滑動到該區增加動態者增加class "js-ani" ， 滑動到該區增加class "is-animated"
	let aniSec = document.querySelectorAll('.js-ani');
	const scrollTriggerAniThrottle = throttle(scrollTriggerAni, 200, 500); // 節流作用

	function scrollTriggerAni() {
		for (var i = 0; i < aniSec.length; i++) {
			if (isInViewport(aniSec[i])) {
				aniSec[i].classList.add('is-animated');
			}
		}

		cleanTrigger();

		/* If load all of the item, stop Listener */
		if (aniSec.length === 0) {
			window.addEventListener('scroll', scrollTriggerAniThrottle);
		}
	}

	function cleanTrigger() {
		aniSec = Array.prototype.filter.call(aniSec, function (ani) {
			return !ani.classList.contains('is-animated');
		});
	}

	function isInViewport(el) {
		const rect = el.getBoundingClientRect();
		const isVisible = el.offsetHeight !== 0;

		return (
			isVisible &&
			rect.bottom >= 0 &&
			rect.right >= 0 &&
			rect.top + 100 <= (window.innerHeight || document.documentElement.clientHeight) &&
			rect.left <= (window.innerWidth || document.documentElement.clientWidth)
		);
	}

	on(window, 'load', scrollTriggerAni);
	on(window, 'scroll', scrollTriggerAniThrottle);
	/* ---------------------------------------- [END] ScrollTriggerAni */

	/* ---------------------------------------- [START] Lazyload */
	/*
	 * 使用：https://github.com/verlok/vanilla-lazyload
	 * 尋找頁面上的 .lazy 為執行 Lazy Load 物件
	 */
	var lazyloadTimer = 0;
	function buildLazyLoad() {
		if (lazyloadTimer < 5 && window.LazyLoad === undefined) {
			return setTimeout(function () {
				lazyloadTimer++;
				buildLazyLoad();
			}, 500);
		}

		var lazyLoadInstance = new LazyLoad({
			// Your custom settings go here
		});
	}
	on(window, 'load', buildLazyLoad);
	/* ---------------------------------------- [END] Lazyload */

	/* ----------------------------------- [START] Loader 移除 */
	var loaderRemove = function () {
		var loader = document.querySelector('#loader');

		if (loader) {
			setTimeout(function () {
				loader.classList.add('loaded');
			}, 50);

			setTimeout(function () {
				loader.remove();
			}, 1000);
		}
	};
	window.addEventListener('load', loaderRemove);
	/* ----------------------------------- [END] Loader 移除 */

	/* ---------------------------------------- [START] 選單下滑更改樣式 */
	const doc = document.documentElement;
	const header = document.querySelector('#hd');
	const footer = document.querySelector('#ft');
	const headerClassScroll = 'is-collapse';

	function headerScrollHandler() {
		const windowScrollTop = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
		if (windowScrollTop >= 50) {
			header.classList.add(headerClassScroll);
		} else {
			header.classList.remove(headerClassScroll);
		}

		if (windowScrollTop >= 50 && windowScrollTop + wh <= footer.offsetTop) {
			$('.js-goto-fixed').addClass('is-show');
		} else {
			$('.js-goto-fixed').removeClass('is-show');
		}
	}
	on(window, 'scroll', headerScrollHandler);
	on(window, 'load', headerScrollHandler);

	// goto top
	$(document).on('click', '.js-goto-fixed', function () {
		$('html, body').stop().animate({ scrollTop: 0 });
	});
	/* ---------------------------------------- [END] 選單下滑更改樣式 */

	/* ---------------------------------------- [START] --vh */
	function mobileFullHeight() {
		var isIE = $('html').hasClass('ie');
		if (!isIE) {
			// 取得視窗高度，再乘以1%得到1vh的值
			var vh = window.innerHeight * 0.01;
			var vw = window.innerWidth * 0.01;
			// 把--vh的值設置到文件的根元素中
			document.documentElement.style.setProperty('--vh', vh + 'px');
			document.documentElement.style.setProperty('--vw', vw + 'px');
		}
	}

	on(window, 'load', mobileFullHeight);
	on(window, 'resize', mobileFullHeight);
	/* ---------------------------------------- [END] --vh */

	/* ---------------------------------------- [START] header選單 */
	function checkIsPc() {
		if (ww > 1024) {
			// stopHtmlScrolling(false);

			$('#hd').removeClass('is-open');
			$('.js-hd-expand').parent().removeClass('is-open');
		}
	}

	on(window, 'resize', checkIsPc);

	function stopHtmlScrolling(boolean) {
		if (boolean) {
			document.querySelector('html').classList.add('stop_scrolling');
		} else {
			document.querySelector('html').classList.remove('stop_scrolling');
		}
	}

	// 漢堡選單
	$(document).on('click', '.js-toggle-hamburger', function () {
		if ($('#hd').hasClass('is-open')) {
			$('#hd').removeClass('is-open');
			// stopHtmlScrolling(false);

			$('.hd_main_block').scrollTop(0);

			if ($('.js-hd-expand')) {
				$('.js-hd-expand').parent().removeClass('is-open');
			}
		} else {
			$('#hd').addClass('is-open');
			// stopHtmlScrolling(true);
		}
	});

	// main選單手機版
	$(document).on('click', '.js-hd-expand', function (e) {
		e.preventDefault();

		const $el = $(this).parent();
		const elClass = '.hd_main_item';

		if (ww < 1024) {
			if ($el.hasClass('is-open')) {
				$el.removeClass('is-open');
			} else {
				$(elClass).removeClass('is-open');
				$el.addClass('is-open');
			}
		}
	});

	// Search Ctrl
	let searchObj = {};

	$(document).on('click', '.js-search-box', function () {
		const $el = $(this).find('.search-form');
		const type = $(this).attr('data-search');

		if (!searchObj[type]) {
			searchObj[type] = false;
		}

		if (searchObj[type]) return false;
		searchObj[type] = true;
		$el.addClass('is-open');
	});

	$(document).on('click', '.js-search-close', function (e) {
		e.stopPropagation();
		const $el = $(this).parents('.search-form');
		const $input = $el.find('.search_input');
		const type = $(this).parents('.search-box').attr('data-search');

		if (!searchObj[type]) return false;
		searchObj[type] = false;
		$el.removeClass('is-open');
		$input.val('');
	});
	/* ---------------------------------------- [END] header選單 */

	/* ---------------------------------------- [START] footer選單 */
	// main選單手機版
	$(document).on('click', '.js-ft-expand', function () {
		const $el = $(this).parent();
		const elClass = '.ft_top_item';

		if (ww < 768) {
			if ($el.hasClass('is-open')) {
				$el.removeClass('is-open');
			} else {
				$(elClass).removeClass('is-open');
				$el.addClass('is-open');
			}
		}
	});
	/* ---------------------------------------- [END] footer選單 */

	/* ---------------------------------------- [START] 示意開啟 modal */
	let openModalId = '';

	$(document).on('click', '[data-modal-open]', function () {
		openModalId = $(this).attr('data-modal-open');

		$('#' + openModalId).addClass('is-active');
		// docDisableScroll();
	});

	// 示意關閉 modal
	$(document).on('click', '[data-modal-close]', function () {
		$('#' + openModalId).removeClass('is-active');
		// docEnableScroll();

		openModalId = '';

		if ($('#video-popup').length) {
			$('#video-popup').find('iframe').remove();
		}
	});
	/* ---------------------------------------- [END] 示意開啟 modal */

	/* ---------------------------------------- [START] 影片彈窗 */

	var youTubeSetting = '';
	var videoIframe = null;

	// 輪播的需要綁定複製的項目
	var videoBox = $('.video-box');
	if (videoBox.length === 0) return false;

	var videoPopup = $('#video-popup');
	var videoPopupPlayer = videoPopup.find('.video-player');

	// Click and load iframe
	$(document).on('click', '.video-box', function () {
		var currentItem = $(this);
		var youTubeUrl = currentItem.attr('data-video');
		var youTubeId = getYoutubeId(youTubeUrl);

		// 寫入Properties
		this.videoId = youTubeId;

		var videoImg = currentItem.find('.video-box__preview');
		var videoPlayPopup = currentItem.find('[data-video-popup]');

		var videoIframeSrc = 'https://www.youtube.com/embed/' + youTubeId + youTubeSetting;

		var youTubeIframeString =
			'<iframe width="1080" height="608" class="video-player" src="' +
			videoIframeSrc +
			'" frameborder="0" allow="autoplay; encrypted-media" allowfullscreen></iframe>';

		videoPopupPlayer.append(youTubeIframeString);
	});

	// 讀取網址
	// get-youtube-info.js: https://gist.github.com/ramonvictor/5629626
	function getYoutubeId(url) {
		var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#\&\?]*).*/;
		var match = url.match(regExp);
		if (match && match[7].length == 11) {
			return match[7];
		}
		return false;
	}
	/* ---------------------------------------- [END] 影片彈窗 */


})(window, document);

// JQuery init
$(document).ready(function () {
	// navbar 導覽選單
	$('.navbar .navbar-link').on('click', function () {
		$(this).closest('.navbar-list').find('.navbar-link').removeClass('is-active');
		$(this).addClass('is-active'); // 新增
	});
});

/* ---------------------------------------- [START] cookie */
// data-max-age：幾天內不會出現，預設180天
(function (window, document) {
	var cookieNotice = document.querySelector('#cookie-notice');
	var cookieMaxAge; // 紀錄時效
	var setName = 'cookieNoticeHide';

	function checkCookie() {
		/* 頁面上沒有 Cookie 物件 => 不執行 */
		if (!cookieNotice) {
			return false;
		}

		var cookieNoticeBtn = cookieNotice.querySelector('button');
		// var cookieNoticeBtnClose = cookieNotice.querySelector('[data-close]');

		/* 確認是否已經有確認Cookie */
		if (getCookie(setName) === 'true') {
			/* 已有確認 */
			cookieNotice.remove();
		} else {
			/* 未確認 */
			cookieNotice.classList.add('is-show');

			/* 取得過期時間設定 */
			cookieMaxAge = cookieNotice.getAttribute('data-max-age') || 180;
			if (typeof cookieMaxAge === 'string') {
				cookieMaxAge = parseInt(cookieMaxAge);
			}
		}

		var closeHandler = function () {
			cookieNotice.classList.remove('is-show');
			setTimeout(function () {
				cookieNotice.remove();
			}, 1000);
		}

		/* 按下「確認」功能 */
		cookieNoticeBtn.addEventListener('click', function () {
			setCookie(setName, true, cookieMaxAge); // 時效設定單位：天
			closeHandler();
		});

		// /* 按下「有連結的按鈕」功能 */
		// if (cookieNoticeBtnAnchor) {
		// 	cookieNoticeBtnAnchor.addEventListener('click', function() {
		// 		closeHandler();
		// 	});
		// }

		/* 按下「關閉」功能 */
		// if (cookieNoticeBtnClose) {
		// 	cookieNoticeBtnClose.addEventListener('click', function() {
		// 		closeHandler();

		// 		// 2022/07/27 客戶希望增加設定，按下叉後，永遠不顯示
		// 		localStorage.setItem('cookieClosed', true);
		// 	});
		// }
	}

	window.addEventListener('load', checkCookie);

	/* ------------------------------------------------------- Tool */
	/* https://stackoverflow.com/a/24103596/11240898 */
	function setCookie(name, value, days) {
		var expires = '';
		if (days) {
			var date = new Date();
			date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
			expires = '; expires=' + date.toUTCString();
		}
		document.cookie = name + '=' + (value || '') + expires + '; path=/';
	}

	function getCookie(name) {
		var nameEQ = name + '=';
		var ca = document.cookie.split(';');
		for (var i = 0; i < ca.length; i++) {
			var c = ca[i].trim();
			if (c.indexOf(nameEQ) === 0) {
				return c.replace(nameEQ, '');
			}
		}
		return null;
	}
})(window, document);
/* ---------------------------------------- [END] cookie */